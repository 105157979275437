body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

nav {background: #6f1e51;color: #fff}
nav {padding: 30px 20px;font-weight: 500}
nav .arrow-right {float: left;fill: #fff}

.searchbar {background: #fff}
.searchbar input {border: 0;width: 100%;border-bottom: 1px solid #eee;font-size: 14px; padding: 10px 10px 10px 45px}

.filterbar {background: #fff;border-bottom: 1px solid #eee;font-size: 14px;text-align: left;padding: 0}
.filterbar select, .filterbar input {padding: 0; border: 0; font-size: 14px}
.filterbar .col-6 {padding: 10px 20px 0}

.filterbar .col-6:first-of-type {border-right: 1px solid #eee}

input,select {outline: none}

.company-list {text-align: left}
.company-row {background: #fff;padding: 20px 0 0;margin: 20px 15px; border-radius: 5px}
.company-title {font-size: 14px;margin-bottom: 10px; margin-left: 10px}
.company-title .company-referenceERP, .company-title .company-name {display: inline-block;}
.company-status {border-radius: 100%;display: inline-block;vertical-align: top; width: 20px;height: 20px;margin-right: 7px}
.company-balance {box-shadow: 0 1px 2px rgba(0, 0, 0, 0.08), 0 0px 2px rgba(0, 0, 0, 0.08);font-size: 14px;text-align: center;margin-top: 10px}
.company-balance .company-balance-row {padding: 10px 0;border: 1px;border-right: 1px solid rgba(255, 255, 255, 0.12)}
.company-balance .company-balance-row.bg-success,
.company-balance .company-balance-row.bg-danger {color: #fff}

.company-title *:not(.company-status) {display: inline}
.company-balance .company-balance-row > div:first-of-type {font-weight: bold}

.search-icon {position: absolute;left: 20px;top: 10px;fill: #aaa;}
.search-icon svg {width: 16px;height: 16px;}

.filtersView {background: #fff;height: 100%;padding: 20px 0 40px;}
.filtersView h3 {font-size: 16px;padding: 20px 20px;margin: 0;background: #f7f7f7;border-radius: 5px 5px 0 0;}
.filtersView .form-check-label {padding: 20px 0 20px 60px;background: #fff;border-radius: 10px;width: 100%;}
.filtersView .filter-row {margin-bottom: 20px;border-radius: 5px;box-shadow: 0 1px 5px #ccc;}
.filtersView .input-field {position: relative }
.filtersView .input-field input {opacity: 0 }
.filtersView .input-field input:checked+.input-field-selector {border: 3px solid #fff }
.filtersView .input-field-selector {opacity: 1;position: absolute; transition: all .3s; margin-top: 2px; background: #fff; border: 1px solid #ccc!important; width: 24px; height: 24px; border-radius: 3px; box-shadow: none;left: 20px;top: 19px;}
.filtersView input:checked+.input-field-selector:before {opacity: 1; -webkit-transform: rotate(45deg) scale(1); transform: rotate(45deg) scale(1) }
.filtersView .input-field input {margin-left: 17px!important }
.filtersView .input-field-selector:before {content: ""; vertical-align: top; line-height: 0; display: inline-block; width: 7px; height: 13px; position: relative; left: 8px; top: 3px; transition: all .3s; opacity: 0; -webkit-transform: rotate(45deg) scale(.5); transform: rotate(45deg) scale(.5); border-bottom: 2px solid #fff; border-right: 2px solid #fff }
.filtersView .input-field input:checked+.input-field-selector {border: 1px solid #888 !important; background: #888;}
.filtersView input:checked + span + span {color: #888 !important;font-weight: 600;}
.btn-primary {background: #888 !important;border-color: #888 !important}
.filtersView .btn {padding: 15px;border-radius: 0}
.filter-btn {margin-top: 12px;display: block;vertical-align: top;font-weight: 600;padding-left: 10px;}
.filter-btn svg {margin-top: 0px;vertical-align: top;float: right;margin-right: 10px;}
.company-row.total {padding: 0}

